<template>
    <div>
        <AddCourse />
        <RecruitmentFormCourse />
        <TutorCourse />
        <CourseTable />
    </div>
</template>

<script>
import AddCourse from '@/components/molecules/AddCourse.vue';
import RecruitmentFormCourse from '@/components/molecules/RecruitmentFormCourse.vue';
import TutorCourse from '@/components/molecules/TutorCourse.vue';
import CourseTable from '@/components/templates/CourseTable.vue';

export default {
    components : {
        CourseTable,
        AddCourse,
		RecruitmentFormCourse,
        TutorCourse
    }
}
</script>

<style>

</style>