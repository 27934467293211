<template>
    <v-container v-if="getUser.role == 'administrator' || getUser.role == 'moderator'" class="my-2  text-left pt-5">
        <v-btn
            color="purple"
            dark
            medium
            @click="showRecruitmentForm = !showRecruitmentForm"
        >
            Wyślij link rejestracyjny
        </v-btn>

        <v-dialog
            v-model="showRecruitmentForm"
            max-width="1000px"
        >
            <v-card>
                <v-card-title>
                    <span>Wyślij link rejestracyjny dla nowego kursanta</span>
                    <span 
                        @click="showRecruitmentForm = !showRecruitmentForm" 
                        class="ml-auto cursor-pointer"
                    >
                    X
                    </span>
                </v-card-title>

                <v-card-text>
                    <v-form ref="form">
                        <v-text-field
                            label="Email"
                            name="email"
                            type="email"
                            v-model="email"
                            clearable
                            :error-messages="emailErrors"
                            @input="$v.email.$touch()"
                            @blur="$v.email.$touch()" 
                            
                        />
                    </v-form>
                </v-card-text>
                <v-card-title >{{feedback}}</v-card-title>
                <v-card-actions class="ml-4 pb-5">
                    <v-btn
                        color="primary"
                        :disabled="$v.$invalid"
                        @click="sendRecruitmentMail"
						:loading="isLoaded"
                    >
                        Wyślij
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {errorMsg} from '@/mixins/Validation';
const { required, email } = require('vuelidate/lib/validators')

const token = localStorage.getItem('user');

export default{
    mixins: [validationMixin, errorMsg],
    components: {
	},
	computed: {
        getUser(){
            return this.$store.getters.getUser;
        },
        getID(){
            return this.$store.getters.getUserID
        }
    },
    validations: {
        email: {
            required,
            email
        },
    },
    name: 'SendMailsApprentice',
    data(){
        return {
            feedback: '',
            email: '',
            users: null,
            isLoaded: false,
            showRecruitmentForm: false
        }
    },

    methods: {
        sendRecruitmentMail(){
            this.isLoaded = true;
            this.$axios.post(`${this.url}api/course/send-recruitment`, {
                email: this.email,
                users: 'kursant'
                
            }, { headers: { 'x-access-token': token } }).then((el) => {
                if(el.data == 'ok'){
                    this.showRecruitmentForm = false;
                    this.email = '';
                    this.isLoaded = false;
                }else {
                    this.isLoaded = false;
                    this.feedback = el.data;
                }
            })
        }


    },
    created(){

    }
}
</script>

<style lang="scss">
.cursor-pointer{
    cursor: pointer;
}
.editr{
    display: none !important;
}
</style>
