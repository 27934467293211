import { render, staticRenderFns } from "./EditCourse.vue?vue&type=template&id=6502f9f8&"
import script from "./EditCourse.vue?vue&type=script&lang=js&"
export * from "./EditCourse.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports