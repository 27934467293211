<template>
    <v-container class="my-2 text-left">
        <v-btn
            color="blue"
            dark
            medium
            v-if="currentRole == 'administrator' || currentRole == 'moderator'"
            @click="show = !show"
        >
            Domyślny opiekun praktyk: {{tutor}}
        </v-btn>

        <v-dialog v-model="show" max-width="1000px">
            <v-card>
                <v-toolbar class="indigo" dark flat>
                    <v-toolbar-title>Ustaw domyślnego opiekuna praktyk</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-switch
                        v-model="switch1"
                        label="Zmień opiekuna praktyk / Dodaj nowego opiekuna"
                    ></v-switch>

                    <v-form ref="form" v-if="!switch1">
                        <v-select
                            label="Wybierz"
                            :items="tutorList"
                            :item-text="'name'"
                            :item-value="'Email'"
                            clearable
                            v-model="email"
                            required
                        ></v-select>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn
                                class="ma-2"
                                color="secondary"
                                @click="add"
                                :loading="isLoaded"
                            >
                                Dodaj
                            </v-btn>
                        </v-card-actions>
                        <!-- <v-text-field
                            label="Email"
                            name="email"
                            type="text"
                            v-model="email"
                            clearable
                            :error-messages="emailErrors"
                            @input="$v.email.$touch()"
                            @blur="$v.email.$touch()" 
                            
                        /> -->
                        <p v-if="feedback">{{ feedback }}</p>
                    </v-form>
                    <v-form ref="form" v-if="switch1">
                        <v-select
                            label="Przypisz opiekunów"
                            :items="filtredKorektor"
                            :item-text="'name'"
                            :item-value="'email'"
                            v-model="tutorList"
                            multiple
                            required
                            clearable
                        ></v-select>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn
                                class="ma-2"
                                color="secondary"
                                @click="addTutorList"
                                :loading="isLoaded"
                            >
                                Dodaj
                            </v-btn>
                        </v-card-actions>
                        <p v-if="feedback">{{ feedback }}</p>
                    </v-form>
                </v-card-text>
                
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
const { required, email } = require('vuelidate/lib/validators')
import { errorMsg } from '@/mixins/Validation';
import io from 'socket.io-client';

const token = localStorage.getItem('user');

export default{
    name: 'AddTutor',
    mixins: [validationMixin, errorMsg],
    computed: {
        currentRole(){
            return this.$store.getters.getUserRole;
        },
        filtredKorektor() {
            return this.copywriters
                .filter(
                    (korektor) =>
                        korektor.role === "korektor" && !korektor.isBanned
                )
                .sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
        },
    },

    data(){
        return{
			isLoaded: false,
            loader: null,
            email: null,
            feedback: null,
            show: false,
            tutor: '',
            tutorEmail: '',
            switch1: false,
            tutorList: [],
            socket: io(this.url),
            copywriters: [],
        }
    },
    validations: {
        email: {
            required,
            email
        },
    },
    methods: {
		resetValidation(){
			this.$refs.form.reset()
			this.$nextTick(() => { this.$v.$reset() })
		},
        add(){
            this.isLoaded = true
            this.$axios.put(`${this.url}api/users/course-tutor`, {
                email: this.email,
                oldEmail:this.tutor
            }, { headers: { 'x-access-token': token } }).then(()=>{
                this.isLoaded = false;
                this.resetValidation()
                this.show = false
            }).catch(()=>{
                this.isLoaded = false;
            })
        },
        get(){
            this.$axios.get(`${this.url}api/users/course-tutor`, { headers: { 'x-access-token': token } })
            .then((el)=>{
                this.tutor = el.data.email
            }).catch(()=>{
                this.isLoaded = false;
            })
        },
        getTutorList(){
            this.$axios.get(`${this.url}api/tutors`, { headers: { 'x-access-token': token } })
            .then((el)=>{
                this.tutorList = el.data[0].list;
                this.tutorId = el.data[0]._id;
                console.log(this.tutorList)
            }).catch((err)=>{
                console.log(err);
            })
        },
        addTutorList(){
            this.isLoaded = true
            console.log(this.tutorList)
            this.$axios.put(`${this.url}api/tutors`, {
                list: this.tutorList,
                id: this.tutorId
            }, { headers: { 'x-access-token': token } }).then(()=>{
                this.isLoaded = false;
                this.resetValidation()
                this.show = false
            }).catch(()=>{
                this.isLoaded = false;
            })
        
        },
        getCopywriters() {
            this.$axios.get(`${this.url}api/users`, {
                headers: { 'x-access-token': token }
            }).then((resp) => {
                this.copywriters = resp.data;
            });
        },

    },
    created(){
        this.get();
        this.getCopywriters();
        this.getTutorList();
        this.socket.on('tutorSet', () => {
            this.get();
        })
    },
    }
</script>
