<template>
    <v-container class="my-5">
		<v-text-field
			@input="setCourseSearch"
            label="Filtruj kursantów"
			:value="getCourseSearch"
            single-line
            hide-details
            class="mb-3"
        ></v-text-field>
        <v-data-table
			:loading="!isDownloaded"
			:search="getCourseSearch"
            :headers="headers"
            item-key="id"
            :items="this.employees"
            class="elevation-2"
            :hide-default-footer="true"
            :disable-pagination="true"
            :sort-desc="false"
        >
            <template v-slot:item.name="{ item }">
                <span :style="{'color': checkIfNeedsAction(item) ? 'red' : 'inherit'}">{{ item.name }}</span>
            </template>

			<template v-slot:item.email="{ item }">
				<v-btn type="button"
				small
                color="indigo"
				dark
				text
				v-clipboard:copy="item.email"
				>
					<v-icon>mdi-content-copy</v-icon>
				</v-btn>
				{{item.email}}
            </template>

            <template v-slot:item.date="{ item }">
                {{ item.date ? item.date.substr(0, 10) : '' }}
            </template>

            <template v-slot:item.correctorId="{ item }">
                {{ item.correctorId ? item.correctorId : "" }}
            </template>
			
            <template v-slot:item.actions="{ item }" >
				<div class="d-flex align-center" v-if="item._id !== odrzucone">
					<v-btn 
					v-if="role == 'administrator' || role == 'moderator' || role == 'korektor'"
						color="green"
						:class="{'opacity': item.isBanned }"
						class="mt-1 mb-1 white--text"
						@click.stop="changeRoute(item._id)"
					>
						Zobacz
					</v-btn>
				

					<EditCourse 
						v-if="role == 'administrator' || role == 'moderator' || role == 'korektor'"
						coursee
						:employee="item"
                        :tutorlist="tutorList"
					/>
					<RemoveCourse 
						v-if="(role == 'administrator' || role == 'moderator') && !item.isBanned"
						:itemRole="item.role"
						:employeeId="item._id" 
					/>
					<RestoreCourse 
						v-if="(role == 'administrator' || role == 'moderator') && item.isBanned"
						:itemRole="item.role" 
						:employeeId="item._id" 
					/>
				</div>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import io from 'socket.io-client';
import EditCourse from '@/components/molecules/EditCourse.vue'
import RestoreCourse from '@/components/molecules/RestoreCourse.vue'
import RemoveCourse from '@/components/molecules/RemoveCourse.vue'

const token = localStorage.getItem('user');

export default {
	components:{
		EditCourse,
		RestoreCourse,
		RemoveCourse
	},
	computed: {
		role() {
            return this.$store.getters.getUserRole
        },
		isLoaded(){
			return this.employees.length == 0
		},
		getCourseSearch(){
			return this.$store.getters.getCourseSearch
		},
        userEmail() {
            return this.$store.getters.getUserMail;
        },
	},
    data(){
        return{
            socket: io(this.url),
            employees: [],
			isDownloaded: false,
			text: 'Pomyślnie usunięto użytkownika',
            timeout: 2000,
            headers: [
                { text: 'Imię i nazwisko', value: 'name', sortable: true  },
                { text: 'Dodano', value: 'date', sortable: true },
                { text: 'Numer telefonu', value: 'phone', sortable: true  },
                { text: 'Email', value: 'email', sortable: true  },
                { text: 'Opiekun', value: 'correctorId', sortable: true  },
                { text: 'Języki', value: 'langs', sortable: true },
                { text: 'Akcje', value: 'actions', sortable: false },
            ],
            tutorList: []
        }
    },
    methods: {
        checkIfNeedsAction(item){
            let action = false;

            item.tasks.forEach(element => {
                if(element.status == 'Do sprawdzenia'){
                    action = true;
                }
            });

            if (action){
                return true;
            }
            return false;
        },
        getTutorList(){
            this.$axios.get(`${this.url}api/tutors`, { headers: { 'x-access-token': token } })
            .then((el)=>{
                this.tutorList = el.data[0].list;
                this.tutorId = el.data[0]._id;
                console.log(this.tutorList)
            }).catch((err)=>{
                console.log(err);
            })
        },
		setCourseSearch(text){
			this.$store.commit('updateFilterCourseSearch', text);
		},
		changeRoute(id){
            this.$router.push(`course/${id}`);
        },
        getCoursesCorrector(){
            this.$axios.get(`${this.url}api/users/courses-corrector/${this.userEmail}`, { headers: { 'x-access-token': token }}).then(resp => {
				this.employees = resp.data
				this.isDownloaded = true;
            }).then(() => {
				this.employees = this.employees.sort(this.dynamicSort("name"));
			});
		},
        getCourses(){
            this.$axios.get(`${this.url}api/users/courses`, { headers: { 'x-access-token': token }}).then(resp => {
				this.employees = resp.data
				this.isDownloaded = true;
            }).then(() => {
				this.employees = this.employees.sort(this.dynamicSort("name"));
			});
		},
		dynamicSort(property) {
			var sortOrder = 1;

			if(property[0] === "-") {
				sortOrder = -1;
				property = property.substr(1);
			}

			return function (a,b) {
				if(sortOrder == -1){
					return b[property].localeCompare(a[property]);
				}else{
					return a[property].localeCompare(b[property]);
				}        
			}
		},
    
    },
    created() {
        this.socket.on('courseAdded', () => {
            if(this.role == "administrator"){
                this.getTutorList()
                this.getCourses()
            }else{
                this.getTutorList()
                this.getCoursesCorrector()
            }
        });
        this.socket.on('courseDeleted', () => {
            if(this.role == "administrator"){
                this.getTutorList()
                this.getCourses()
            }else{
                this.getTutorList()
                this.getCoursesCorrector()
            }
        });
        this.socket.on('courseUpdated', () => {
            if(this.role == "administrator"){
                this.getTutorList()
                this.getCourses()
            }else{
                this.getTutorList()
                this.getCoursesCorrector()
            }
        });
        this.socket.on('courseDeleted', () => {
            if(this.role == "administrator"){
                this.getTutorList()
                this.getCourses()
            }else{
                this.getTutorList()
                this.getCoursesCorrector()
            }
        });
        if(this.role == "administrator"){
            this.getTutorList()
            this.getCourses()
        }else{
            this.getTutorList()
            this.getCoursesCorrector()
        }
        
    },
}
</script>


<style scoped lang="scss">
	.opacity{
		opacity: .6 !important;
	}
</style>